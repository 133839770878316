import React from 'react';
import { standIcon } from '../../ui/icons/business-objects';
import { PageTab } from '../../ui/page';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import COMPANY_CHILD_ROUTES from '../company/company-child-routes';
import { COMPANY_PAGE_TAB } from '../company/company-page/company-page-tab';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { PERSON_RELATION_EXTENSION } from '../person/person-relation/person-relation-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import companyStandRoute from './stand-companies/company-stand-route';
import {
  useCompanyStandPermissionClassGroupExtension,
  usePersonStandPermissionClassGroupExtension,
  useStandPermissionClassGroupExtension,
} from './stand-permissions';
import { PersonStandRelations } from './stand-persons/person-stand-relations';
import StandSearchResult from './stand-search-result';

export default function StandPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'STAND', Component: StandSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'STAND',
    icon: standIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.STAND" />,
  });
  registry.register(PERSON_RELATION_EXTENSION, {
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.STAND" />,
    element: (person) => <PersonStandRelations person={person} />,
  });
  registry.register(COMPANY_PAGE_TAB, (builder) =>
    builder.before('history', {
      key: 'stands',
      element: (
        <PageTab to="./stands">
          <Translate ns="stand" i18nKey="lister.title" />
        </PageTab>
      ),
    }),
  );
  registry.register(COMPANY_CHILD_ROUTES, companyStandRoute);

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useStandPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, usePersonStandPermissionClassGroupExtension);
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useCompanyStandPermissionClassGroupExtension);
}
