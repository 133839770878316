import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { standIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function StandSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'STAND', `${result.resultType} is no instance of StandSearchResult`);

  const stand = result.result;
  const { t } = useTranslation('stand');

  return (
    <SearchResult
      icon={standIcon}
      title={stand.name}
      link={`/stands/${stand.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="STAND:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('standNumber'),
            element: stand.standNumber,
          },
          {
            label: t('edition'),
            element: stand.edition.name,
          },
          {
            label: t('location.label'),
            element: stand.location.location.name,
          },
          {
            label: t('status'),
            element: t(`statusOptions.${stand.status}`),
          },
        ]}
      />
    </SearchResult>
  );
}
